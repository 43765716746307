<template>
  <div>
    <div class="vx-row mb-6">
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Territory</label>
        <Territory @data="setTerritory" :params="this.terr_params" :isMultiple="true" />
      </div>
      <!-- <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Sales channel</label>
        <SalesChannel @data="setSalesChannel" :isMultiple="true" />
      </div> -->
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Status GIR</label>
        <multiselect v-model="status_gir_selected" :options="status_gir_option" track-by="name" label="name"
					:max-height="125" :allow-empty="true" :select-label="''" deselect-label="">
					<span slot="noResult">Oops! No data found</span>
					<template slot="singleLabel" slot-scope="props">
						<span class="option__desc">
							<span class="option__title">{{ props.option.name }}</span>
						</span>
					</template>

					<template slot="option" slot-scope="props">
						<div class="option__desc">
							<span class="option__title">{{ props.option.name }}</span>
						</div>
					</template>
				</multiselect>
      </div>
      <div class="vx-col md:w-1/2">
        <label class="vs-input--label">Distribution Channel</label>
        <DistributionChannel
          @data="setDistrubutionChannel"
          :params="this.dc_params"
          :isMultiple="true"
        />
      </div>
      <div class="vx-col md:w-1/2">
        <!-- <label class="vs-input--label">Sales</label>
        <Sales @data="setSales" :isMultiple="true" :isGetData="true" /> -->
      </div>

      <div class="vx-col md:w-1/4">
        <label class="vs-input--label">Date From</label>
        <vs-input
          type="date"
          v-model="form.date_from"
          class="w-full"
        ></vs-input>
      </div>
      <div class="vx-col md:w-1/4">
        <label class="vs-input--label">Date To</label>
        <vs-input type="date" v-model="form.date_to" class="w-full"></vs-input>
      </div>
    </div>

    <!-- btn filter -->
    <vs-row>
      <vs-col>
        <vs-button
          color="primary"
          type="filled"
          class="my-4"
          icon="search"
          @click="clickFilter"
          >Filter</vs-button
        >
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import Territory from "@/components/master/Territory.vue";
import DistributionChannel from "@/components/master/DistributionChannel.vue";
// import SalesChannel from "@/components/master/SalesChannel.vue";
// import Sales from "@/components/master/Sales.vue";

export default {
  components: {
    Territory,
    DistributionChannel,
    // SalesChannel,
    // Sales,
  },
  props: [
    "indexfil", "count",
  ],
	async mounted() {
    if (this.count > 0) {
      console.log("indexfil", this.indexfil);
      console.log("this.count", this.count);
      console.log("this.indexfil", this.indexfil);
      this.setFilter();
    }
  },
  data() {
    return {
      form: {
        territory_ids: [],
        distribution_channel_ids: [],
        // sales_channel_ids: [],
        // sales_ids: [],
        status_gir: "",
        date_from: "",
        date_to: "",
      },
      status_gir_selected: {},
      status_gir_option: [
        {"name": "Completed"},
        {"name": "Partial"},
        {"name": "Not Yet"}
      ],
			terr_params : this.$userLogin.role[0].Group == "Sales" ? { 
				params : {
					id : this.$userLogin.territory_id,
				},
			} : {params : {selected: this.indexfil.territory_ids ? this.indexfil.territory_ids : false }},
      dc_params : {params : {selected: this.indexfil.distribution_channel_ids ? this.indexfil.distribution_channel_ids : false }},
    };
  },
  methods: {
    async setDistrubutionChannel(datas) {
      this.form.distribution_channel_ids = [];
      datas.forEach((data) => {
        this.form.distribution_channel_ids.push(data.ID);
      });
    },
    async setSalesChannel(data) {
      this.form.sales_channel_ids = [];
      data.forEach((item) => {
        this.form.sales_channel_ids.push(item.ID);
      });
    },
    async setTerritory(data) {
      this.form.territory_ids = [];
      data.forEach((item) => {
        this.form.territory_ids.push(item.id);
      });
    },
    async setSales(data) {
      this.form.sales_ids = [];
      data.forEach((item) => {
        this.form.sales_ids.push(item.id);
      });
    },
    async clickFilter() {
      this.form.status_gir = this.status_gir_selected ? this.status_gir_selected.name : null
      this.$emit("data", this.form);
    },
    async setFilter() {
      this.form = {
        territory_ids: [],
        distribution_channel_ids: [],
        status_gir: "",
        date_from: "",
        date_to: "",
      };
      console.log("this.form", this.form);
      if (this.indexfil != {}) {
        this.form.date_from = this.indexfil.date_from != undefined ? this.indexfil.date_from : "";
        this.form.date_to = this.indexfil.date_to != undefined ? this.indexfil.date_to : "";
        
        if (this.indexfil.distribution_channel_ids.length > 0) {
          this.indexfil.distribution_channel_ids.forEach(dc => {
            this.form.distribution_channel_ids.push(dc);
          });
        }

        if (this.indexfil.territory_ids.length > 0) {
          this.indexfil.territory_ids.forEach(ter => {
            this.form.territory_ids.push(ter);
          });
        }
        
        if (this.indexfil.status_gir != undefined && this.indexfil.status_gir != null) {
          this.status_gir_selected = this.status_gir_option.filter((v) => {
            return v.name == this.indexfil.status_gir;
          });
          this.form.status_gir = this.indexfil.status_gir;
        }
      }
      console.log("this.form", this.form);
      this.$emit("data", this.form);
    },
  },
};
</script>
