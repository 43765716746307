<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="ID"
      label="Name"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :disabled="computedIsDisabled"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title">{{ props.option.Name }}</span>
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.Name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const salesReturnV2Repository = RepositoryFactory.get("salesReturnV2");
export default {
  props: {
    isGetData: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
    };
  },
  computed: {
    computedIsDisabled() {
      return this.isDisabled;
    },
  },
  methods: {
    async setByID(id) {
      this.options.forEach(function (item) {
        if (item.ID == id) {
          this.selected = item;
        }
      }, this);
    },
    async getData() {
      try {
        const resp = await salesReturnV2Repository.getCNReturnTypes();
        if (resp.code == 200) {
          var options = [];
          resp.data.forEach(function (item) {
            options.push(item);
          }, options);
          this.options = options;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Error while fetching return types",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching return types",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
    },
    async setDefaultCNReturnType() {
      await this.options.forEach((element) => {
        if (element.Name == "Credit Note With Return") {
          this.selected = element;
        }
      });
    },
    setToggleDisabled() {
      this.isDisabled = !this.isDisabled;
    },
    setDisabled(value) {
      this.isDisabled = value;
    },
  },
  watch: {
    selected(v) {
      this.selected = v;
      this.$emit("data", v);
    },
  },
  async mounted() {
    if (this.isGetData) {
      await this.getData();
    }
  },
};
</script>
