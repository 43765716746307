<template>
  <div v-if="detail">
    <DetailComponent
      :srID="srID"
      :dataSR="dataSR"
      :dataFil="filter"
      :isDetailForm="isDetail"
      @loading="handleLoading"
      @handleClose="handleClose"
    />
  </div>
  <vx-card v-else title="Sales Return">
    <!-- create btn -->
    <div class="flex justify-start">
      <vs-button
        @click="clickCreate"
        color="primary"
        class="mb-4"
        type="filled"
      >
        Create
      </vs-button>
    </div>

    <!-- filter section -->
    <div>
      <HeaderFilter :indexfil="filter" :count="filterCount" @data="setFilterData" />
    </div>

    <vs-tabs v-model="tab">
      <vs-tab label="Draft">
        <div class="tab-text">
          <New :filter="filter" @clickEdit_="handleClickEdit" ref="table_new" />
        </div>
      </vs-tab>
      <vs-tab label="Inquiry">
        <div class="tab-text">
          <Inquiry :filter="filter" @clickEdit_="handleClickEdit" ref="table_inquiry" />
        </div>
      </vs-tab>
      <vs-tab label="Waiting">
        <div class="tab-text">
          <Waiting :filter="filter" @clickEdit_="handleClickEdit" ref="table_waiting" />
        </div>
      </vs-tab>
      <!-- <vs-tab label="Approved">
        <div class="tab-text">
          <Approved :filter="filter" ref="table_approved" />
        </div>
      </vs-tab> -->
      <vs-tab label="Released">
        <div class="tab-text">
          <Released :filter="filter" @clickEdit_="handleClickEdit" ref="table_released" />
        </div>
      </vs-tab>
      <vs-tab label="Closed">
        <div class="tab-text">
          <Closed :filter="filter" @clickEdit_="handleClickEdit" ref="table_closed" />
        </div>
      </vs-tab>
      <vs-tab label="Rejected">
        <div class="tab-text">
          <Rejected :filter="filter" @clickEdit_="handleClickEdit" ref="table_rejected" />
        </div>
      </vs-tab>
      <vs-tab label="Canceled">
        <div class="tab-text">
          <Canceled :filter="filter" @clickEdit_="handleClickEdit" ref="table_canceled" />
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import HeaderFilter from "./_components/header-filter.vue";
import New from "./new/index.vue";
import Waiting from "./waiting/index.vue";
import Approved from "./approved/index.vue";
import Released from "./released/index.vue";
import Rejected from "./rejected/index.vue";
import Canceled from "./canceled/index.vue";
import Inquiry from "./inquiry/index";
import Closed from "./closed/index.vue";
import DetailComponent from "./form-comp.vue";

export default {
  name: "sales-return-v2-index",
  components: {
    New,
    HeaderFilter,
    Approved,
    Released,
    Rejected,
    Canceled,
    Waiting,
    Inquiry,
    Closed,
    DetailComponent,
  },
  data() {
    return {
      filter: {},
      isClickFilter: false,
      detail: false,
      isDetail: false,
      srID: null,
      dataSR: null,
      filterCount: 0,
      tab: 0,
    };
  },
  methods: {
    clickCreate() {
      // this.$router.push({
      //   name: "sales-return-v2-form",
      //   params: { status: "create" },
      // });
      this.isDetail = false;
      this.detail = true;
      this.srID = null;
      this.dataSR = null;
    },
    async setFilterData(data) {
      this.filter = data;
      this.filterCount++;
      this.isClickFilter = true;
      this.tab = 0;
      if (this.$refs.table_new) {
        this.tab = 0;
        this.$refs.table_new.getData();
      } else if (this.$refs.table_waiting) {
        this.tab = 2;
        this.$refs.table_waiting.getData();
      } else if (this.$refs.table_approved) {
        this.tab = null;
        this.$refs.table_approved.getData();
      } else if (this.$refs.table_released) {
        this.tab = 3;
        this.$refs.table_released.getData();
      } else if (this.$refs.table_rejected) {
        this.tab = 5;
        this.$refs.table_rejected.getData();
      } else if (this.$refs.table_canceled) {
        this.tab = 6;
        this.$refs.table_canceled.getData();
      } else if (this.$refs.table_inquiry) {
        this.tab = 1;
        this.$refs.table_inquiry.getData();
      } else if (this.$refs.table_closed) {
        this.tab = 4;
        this.$refs.table_closed.getData();
      }
    },
    async handleClose(datafil) {
      this.detail = false;
      this.srID = null;
      this.dataSR = null;
      this.filter = datafil;
      
      // this.tab = fromTab;

      console.log("this.filter", this.filter)
      console.log("this.filterCount", this.filterCount)
      console.log("this.$refs", this.$refs);
    },
    handleClickEdit(srID, dataSR, fromTab){
      this.srID = srID;
      this.dataSR = dataSR;
      this.isDetail = (fromTab != "new_tab" && fromTab != "inquiry");
      this.detail = true;
      console.log("this.detail", this.detail);
      console.log("this.srID", this.srID);
      console.log("this.dataSR", this.dataSR);
      console.log("srID", srID);
      console.log("dataSR", dataSR);
      console.log("fromTab", fromTab);
      console.log("this.$refs", this.$refs);
      
    },
  },
};
</script>
