<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
    >
      <template slot="thead">
        <vs-th>Row</vs-th>
        <vs-th sort-key="">Status</vs-th>
        <vs-th sort-key="">Message</vs-th>
        <vs-th sort-key="">Data</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ displayRow(tr.data, indextr) }}</vs-td>
          <vs-td> {{ tr.status }} </vs-td>
          <vs-td> {{ tr.remark }} </vs-td>
          <vs-td>
            <div v-html="displayData(tr.data)"></div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import { dateFormat } from "@/utils/helper";

const ImportRepository = RepositoryFactory.get("import");

export default {
  components: {},
  props: {
    filter: {
      type: Array,
      default: () => {
        return [];
      },
    },
    parentID: {
      type: Number,
      required: true,
      default: 0,
    },
    isGetData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      table: this.tableDefaultState(),
    };
  },
  mounted() {},
  methods: {
    displayRow(data, index) {
      // if data.row is not exist, return index + 1
      return data.row ? data.row : index + 1;
    },
    displayData(data) {
      // convert object to html list
      let html = "<ul>";
      for (const key in data) {
        html += `<li><strong>${key}</strong>: ${data[key]}</li>`;
      }
      html += "</ul>";
      return html;
    },
    dateFormat,
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    async getData() {
      this.$vs.loading();
      try {
        const id = this.parentID;
        const resp = await ImportRepository.getDetail(id);
        if (resp.code == 200) {
          this.table.data = resp.data;
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while draft sales price",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    setFilter(data) {
      this.table.page = 1;
      this.filter = data;
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.table.page = val;
        this.handleChangePage(val);
      },
    },
  },
};
</script>
