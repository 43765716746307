<template>
  <div class="flex gap-2">
    <vs-input type="text" class="w-full" v-model="code" :disabled="isDisable" />
    <vs-button
      color="primary"
      type="filled"
      icon="search"
      @click="findByCode(code)"
      :disabled="isDisable"
      >Search</vs-button
    >
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";

const MasterRepository = RepositoryFactory.get("master");

export default {
  props: {
    isDisable: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      code: null,
      data: null,
    };
  },
  methods: {
    async setCode(code) {
      this.code = code;
    },
    async findByCode(code) {
      if (code == "") {
        return;
      }

      this.$vs.loading();
      try {
        const resp = await MasterRepository.getInvoiceByCode(code, {
          params: {
            show_all_hu: true,
          },
        });
        if (resp.code == 200) {
          this.data = resp.data;
          this.$emit("data", this.data);
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Error while fetching customer invoice: " + resp.message,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching customer invoice: " + error.message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
      this.$vs.loading.close();
    },
    async setDisabled(disabled) {
      this.isDisable = disabled;
    },
  },
};
</script>
