<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="ID"
      label="id"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :hide-selected="true"
      :internal-search="false"
      :searchable="true"
      @search-change="handleSearch"
      :disabled="isDisabled"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title">{{ props.option.Name }}</span>
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.Name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const masterRepository = RepositoryFactory.get("master");
import _ from "lodash";

export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isGetData: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
      params: {
        search: null,
        tax_group: null,
      },
    };
  },
  methods: {
    setDisabled(value) {
      this.isDisabled = value;
    },
    handleSearch: _.debounce(async function (search) {
      if (search != "") {
        this.params.search = search;
        await this.getData();
      }
    }, 1000),
    async setByID(id) {
      try {
        const resp = await masterRepository.getTaxesByID(id);
        if (resp.code == 200) {
          this.selected = resp.data;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Error while fetching master tax",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          console.log(resp);
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching master tax",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
    },
    async setParams(params) {
      this.params = params;
    },
    async getData() {
      try {
        const resp = await masterRepository.getTaxes({
          params: this.params,
        });
        if (resp.code == 200) {
          this.options = [];
          var options = [];

          for (let i = 0; i < resp.data.records.length; i++) {
            options.push(resp.data.records[i]);
          }

          this.options = options;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Error while fetching master tax",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          console.log(resp);
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching master tax",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
    },
  },
  watch: {
    selected(v) {
      this.selected = v;
      this.$emit("data", v);
    },
  },
  mounted() {
    if (this.isGetData) {
      this.getData();
    }
  },
};
</script>
