<template>
  <div>
    <div class="my-2">
      <label class="vs-input--label"
        >Attachment
        <small style="color: red"
          >(only: jpg, jpeg, png, pdf, doc, docx, xls, xlsx)</small
        >
      </label>
      <InputFile
        :acceptedTypes="acceptedTypes"
        @data="setAttachment"
        setPathUpload="sales-return-v2"
        :isWithAPI="true"
        ref="inputFile"
      />

      <vs-divider> List Attachments </vs-divider>
      <vs-table :data="attactments">
        <template slot="thead">
          <vs-th>File Name</vs-th>
          <vs-th>Actions</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(item, indextr) in data">
            <vs-td>{{ item.name }}</vs-td>
            <vs-td>
              <div class="flex gap-2">
                <vs-button
                  color="primary"
                  type="filled"
                  size="small"
                  @click="downloadAttachment(item.path)"
                >
                  Download
                </vs-button>
                <vs-button
                  color="danger"
                  type="filled"
                  size="small"
                  @click="deleteAttachment(indextr)"
                  icon-pack="feather"
                  icon="icon-trash"
                />
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import InputFile from "@/components/master/InputFile.vue";

export default {
  components: {
    InputFile,
  },
  data() {
    return {
      attactments: [],
      acceptedTypes: [
        "jpg",
        "jpeg",
        "png",
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
      ],
    };
  },
  methods: {
    setDefaultAttachment(data) {
      // data is array string convert to array object
      this.attactments = data.map((item) => {
        return {
          name: item.split("/").pop(),
          path: item,
        };
      });
    },
    setAttachment(data) {
      let datas = [];
      data.forEach((item) => {
        // "https://s3.ap-southeast-1.amazonaws.com/zest-dms-stgg/public/2/OMS/sales-return-v22024/6/18/170807Y1VSe_x0absdq870lhm8rat1fz.png"
        // get last index of /
        let name = item.split("/").pop();
        datas.push({
          name: name,
          path: item,
        });
      });

      this.attactments = [...this.attactments, ...datas];
    },
    downloadAttachment(path) {
      window.open(path, "_blank");
    },
    deleteAttachment(indextr) {
      this.attactments.splice(indextr, 1);
    },
  },
  watch: {
    attactments: {
      handler: function (val) {
        // return array string only
        let newVal = val.map((item) => item.path);

        this.$emit("data", newVal);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.my-2 {
  margin-top: 2rem;
}
</style>
