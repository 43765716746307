<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="ID"
      label="reason"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :disabled="isDisabled"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title">{{ props.option.reason }}</span>
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.reason }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
const masterRepository = RepositoryFactory.get("master");

export default {
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isGetData: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    reasonGroup: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
      params: {
        reason_group: this.reasonGroup,
      },
    };
  },
  methods: {
    setDisabled(value) {
      this.isDisabled = value;
    },
    async setByID(id) {
      try {
        const resp = await masterRepository.getMasterReasonByID(id);
        if (resp.code == 200) {
          this.selected = resp.data;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Error while fetching master reason",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          console.log(resp);
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching master reason",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
    },
    async getData() {
      try {
        const resp = await masterRepository.getMasterReason({
          params: this.params,
        });
        if (resp.code == 200) {
          var options = [];
          resp.data.records.forEach(function (item) {
            options.push(item);
          }, options);
          this.options = options;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Error while fetching master reason",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          console.log(resp);
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching master reason",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
    },
  },
  watch: {
    selected(v) {
      this.selected = v;
      this.$emit("data", v);
    },
  },
  mounted() {
    if (this.isGetData) {
      this.getData();
    }
  },
};
</script>
