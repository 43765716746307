import moment from "moment";

// dateformat
export const dateFormat = (date, format) => {
  return moment(date).format(format);
};

// currency format IDR 1.000.000
export const currencyFormatIDR = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(number);
};

// number format 1.000.000
export const numberFormat = (number) => {
  return new Intl.NumberFormat("id-ID").format(number);
};

// array string to string with separator
export const arrayToString = (array, separator) => {
  if (!array || !separator) {
    return "";
  }
  if (array.length === 0) {
    return "";
  }
  return array.join(separator);
};

// convert "16/04/2024 09:46:04" to "2024-04-16T09:46:04"
export const convertStringToISO = (date) => {
  // date = "16/04/2024 09:46:04" to "2024-04-16T09:46:04"
  let dateOnly = date.split(" ")[0];
  let time = "T" + date.split(" ")[1];
  let newDate = dateOnly.split("/").reverse().join("-");

  return newDate + time;
};

// convert UTC to local time for example: "16/04/2024 09:46:04" to "16/04/2024 16:46:04"
export const convertUTCtoLocal = (date) => {
  return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
};

export const convertUTCtoLocalDateTime = (date) => {
  if (!date) {
    return "-";
  }
  return moment.utc(date).format("DD/MM/YYYY HH:mm:ss");
};

export const convertUTCtoLocalDate = (date) => {
  if (!date) {
    return "-";
  }
  return moment.utc(date).local().format("DD/MM/YYYY");
};

// convert date from 01/01/2024 to 2024-01-01
export const convertDateFormat = (date) => {
  return date.split("/").reverse().join("-");
};
