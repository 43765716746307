<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="name"
      label="name"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :disabled="isDisabled"
      :searchable="isSearchable"
      :hide-selected="true"
      :internal-search="false"
      @search-change="onSearch"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title"
            >{{ props.option.code }} {{ props.option.name }}</span
          >
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title"
            >{{ props.option.code }} {{ props.option.name }}</span
          >
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import _ from "lodash";
const MasterRepository = RepositoryFactory.get("master");
export default {
  props: {
    isSearchable: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    isWithoutSoRef: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
      ids: [],
      codes: [],
      params: {
        search: "",
        length: 10,
      },
    };
  },
  methods: {
    setParams(params) {
      this.params = params;
    },
    setDisabled(isDisabled) {
      this.isDisabled = isDisabled;
    },
    onSearch: _.debounce(function (query) {
      this.params.search = query;
      if (query != "") {
        this.getData();
      }
    }, 1000),
    async setIds(ids) {
      // check ids is array
      if (!Array.isArray(ids)) {
        this.ids = [ids];
        await this.getDataByIds();
      } else {
        this.ids = ids;
        await this.getDataByIds();
      }
    },
    async setCodes(codes) {
      // check codes is array
      if (!Array.isArray(codes)) {
        this.codes = [codes];
        await this.getDataByCodes();
      } else {
        this.codes = codes;
        await this.getDataByCodes();
      }
    },
    async toggleDisabled() {
      this.isDisabled = !this.isDisabled;
    },
    async setIsDisabled(isDisabled) {
      this.isDisabled = isDisabled;
    },
    async getDataByCodes() {
      this.$vs.loading();
      try {
        const resp = await MasterRepository.getCustomersByCodes(this.codes);
        if (resp.code == 200) {
          var options = [];
          if (resp.data.length == 1) {
            options.push(resp.data[0]);
            this.options = options;
            this.selected = resp.data[0];
          } else if (resp.data.length > 1) {
            resp.data.forEach(function (item) {
              options.push(item);
            }, options);
            this.options = options;
          }
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching territories",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
    async getDataByIds() {
      this.$vs.loading();
      try {
        const resp = await MasterRepository.getCustomersByID(this.ids);
        if (resp.code == 200) {
          var options = [];
          if (resp.data.length == 1) {
            options.push(resp.data[0]);
            this.options = options;
            this.selected = resp.data[0];
          } else if (resp.data.length > 1) {
            resp.data.forEach(function (item) {
              options.push(item);
            }, options);
            this.options = options;
          }
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching territories",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
    async getData() {
      this.$vs.loading();
      try {
        if (this.isWithoutSoRef) {
          this.params.sr_without_ref = true;
        }

        const resp = await MasterRepository.getCustomersV2({
          params: this.params,
        });
        if (resp.code == 200) {
          this.options = [];
          this.options = resp.data.records;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Error while fetching customers",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching customers",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
  },
  watch: {
    selected(v) {
      this.selected = v;
      this.$emit("data", v);
    },
  },
};
</script>
