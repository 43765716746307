<template>
  <div class="w-full">
    <vs-input
      v-if="components.type == 'input'"
      :class="
        'w-full ' + this.components.text_align ? components.text_align : ''
      "
      v-validate="components.validate"
      :name="components.name"
      v-model="components.value"
      :style="{
        width: components.width
          ? components.width + 'px !important'
          : 100 + '%',
      }"
      :disabled="components.disabled"
      :placeholder="components.placeholder"
      @keypress="keypress($event)"
      @input="oninputitem"
    />

    <vs-textarea
      v-else-if="components.type == 'textarea'"
      v-validate="components.validate"
      v-model="components.value"
      :disabled="components.disabled"
    />

    <multiselect
      v-else-if="components.type == 'multiselect'"
      class="selectExample"
      v-model="components.value"
      :options="components.optionValue"
      :multiple="false"
      :allow-empty="false"
      :searchable="true"
      :internal-search="false"
      :group-select="false"
      :select-label="''"
      deselect-label=""
      :max-height="160"
      :loading="components.isLoading"
      :limit="3"
      :placeholder="components.placeholder"
      @search-change="handlerSearchSelect"
      @input="onselected"
      :track-by="components.track_by ? components.track_by : 'id'"
      :disabled="components.disabled"
    >
      <template slot="singleLabel" slot-scope="dt">
        <span class="option__desc">
          <span class="option__title">
            {{ dt.option.text }}
          </span>
        </span>
      </template>

      <template slot="option" slot-scope="dt">
        <div class="option__desc">
          <span class="option__title">
            {{ dt.option.text }}
          </span>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
//   import moment from "moment";
export default {
  components: {},
  props: ["components", "i_data"],
  data() {
    return {
      dt: "",
    };
  },
  mounted() {
    console.log("hallo", this.components);
  },
  methods: {
    oninputitem() {
      this.$emit("oninputitem", this.components, "itemref");
    },

    onselected(select) {
      this.$emit("onSelected", select, this.components, this.i_data);
    },
    handlerSearchSelect(search) {
      this.$emit("search-change", search, this.components);
    },
    keypress(evt) {
      if (
        this.components.name == "qty" ||
        this.components.name == "price" ||
        this.components.name == "discount" ||
        this.components.name == "tax"
      ) {
        return this.isNumber(evt);
      }
      return true;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (!(charCode > 47 && charCode < 58)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    maxLength(evt, max) {
      var length = this.components.value.length;
      console.log(length);
      console.log(length + 1 <= max);
      if (length + 1 <= max) {
        return true;
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "value must be less than " + max + " characters",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        evt.preventDefault();
      }
    },
  },
};
</script>
<style>
.text-center input {
  text-align: center !important;
}
</style>
