<template>
  <div>
    <vs-table
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="thead">
        <vs-th sort-key="">Status</vs-th>
        <vs-th sort-key="">Role</vs-th>
        <vs-th sort-key="">Level</vs-th>
        <vs-th sort-key="">Keterangan</vs-th>
        <vs-th sort-key="">Created At</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <vs-chip :color="tr.Status | textStatusColor" class="capitalize">
              {{ tr.Status | textStatus }}
            </vs-chip>
          </vs-td>
          <vs-td> {{ tr.Role.DisplayName }}</vs-td>
          <vs-td> {{ tr.Level }} </vs-td>
          <vs-td> {{ tr.Keterangan }} </vs-td>
          <vs-td> {{ convertUTCtoLocalDateTime(tr.CreatedAt) }} </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>

<style>
.con-vs-popup .vs-popup {
  width: 100% !important;
}
</style>

<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import { convertUTCtoLocalDateTime } from "@/utils/helper";
import TableLine from "@/components/import/TableLine.vue";

const ApprovalRepository = RepositoryFactory.get("approval");

export default {
  components: {
    TableLine,
  },
  props: {
    filter: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      table: this.tableDefaultState(),
      activeModalDetail: false,
      activeParentID: 0,
    };
  },
  filters: {
    textStatus(status) {
      if (status == 1) {
        return "Approved";
      } else if (status == 2) {
        return "Rejected";
      } else if (status == 11) {
        return "Inquiry";
      } else {
        return "Pending";
      }
    },
    textStatusColor(status) {
      if (status == 1) {
        return "success";
      } else if (status == 2) {
        return "danger";
      } else if (status == 11) {
        return "dark";
      } else {
        return "warning";
      }
    },
  },
  methods: {
    closeModal() {
      this.activeModalDetail = false;
    },
    openModalDetail(data) {
      this.activeModalDetail = true;
      console.log("data", data);
      this.activeParentID = data.id;
      this.$nextTick(() => {
        this.$refs.tableLine.getData();
      });
    },
    convertUTCtoLocalDateTime,
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    async getData() {
      this.$vs.loading();
      try {
        const params = {
          page: this.table.page,
          length: this.table.length,
          order: this.table.order,
          sort: this.table.sort,
          search: this.table.search,
          ...this.filter,
        };

        const resp = await ApprovalRepository.getApprovalLinesList(params);
        this.table.data = [];
        this.table.total = 0;
        this.table.totalPage = 0;
        this.table.totalSearch = 0;
        this.table.length = 0;
        if (resp.code == 200) {
          this.table.data = [];
          this.table.total = resp.data.total_record;
          this.table.totalPage = resp.data.total_page;
          this.table.totalSearch = resp.data.total_record_search;
          this.table.length = resp.data.total_record_per_page;
          this.table.data = resp.data.records;
          this.setStartEnd();
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching data",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    setFilter(data) {
      this.table.page = 1;
      this.filter = data;
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.table.page = val;
        this.handleChangePage(val);
      },
    },
  },
};
</script>
