<template>
  <div>
    <p class="text-lg">Pickup Request</p>
    <hr />

    <div class="my-2">
      <label class="vs-input--label">Pickup</label>
      <vs-checkbox
        color="success"
        vs-value="true"
        vs-true-value="true"
        vs-false-value="false"
        v-model="form.pickup_request.is_pickup"
        :disabled="form.isDetail == 1"
      />
    </div>
    <div class="my-2">
      <label class="vs-input--label">Pickup Date</label>
      <vs-input
        type="date"
        class="w-full"
        v-model="form.pickup_request.pickup_date"
        :disabled="form.isDetail == 1"
        :min="today"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      today: new Date().toISOString().split("T")[0],
    };
  },
};
</script>
